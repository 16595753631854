<template>
  <b-overlay :show="loading">
    <b-card header="Galleria">
      <b-row
        align-h="between"
        class="mt-1"
      >
        <b-col cols="6">
          <b-button-group>
            <aggiungi-documento
              v-if="canAdd"
              :scheda-id="scheda.id"
              :difetti="difetti"
              @onAdd="add"
            />
            <span style="margin: 0 1px" />
            <!--            <div>-->
            <!--              <b-button-->
            <!--                variant="primary"-->
            <!--                title="Toggle Visuale"-->
            <!--                @click="toggleVisuale"-->
            <!--              >-->
            <!--                <feather-icon :icon="icon" />-->
            <!--              </b-button>-->
            <!--            </div>-->

          </b-button-group>
        </b-col>
        <!-- input search -->
        <b-col
          class="d-flex justify-content-end"
          cols="6"
        />
      </b-row>

      <b-card-body>
        <viewer
          ref="viewer"
          :images="images"
          class="viewer"
          @inited="inited"
        >
          <template #default="scope">
            <b-row class="justify-content-center">
              <b-col
                v-for="src in scope.images"
                :key="src.path"
                style="
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                  width: fit-content;
                  flex-grow: 0;
                "
                class="m-1 p-0"
              >
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    width: 300px;
                    height: 200px;
                    margin: 4px;
                  "
                >
                  <img
                    :src="src.path"
                    style="
                      flex-shrink: 0;
                      min-width: 100%;
                      min-height: 100%;
                    "
                    alt=""
                  >
                </div>
                <div class="p-1">
                  <b-row class="mr-0">
                    <b-col cols="9">
                      <span><b>Nome: &nbsp;</b> {{ src.nome }}</span>
                      <br>
                      <br v-if="src.descrizione">
                      <span v-if="src.descrizione"><b>Descrizione: &nbsp;</b> {{ src.nome }}</span>
                      <br v-if="src.descrizione">
                      <br>
                      <span v-if="src.difetto"> <b>Difetto: &nbsp;</b> {{ src.difetto }} </span>
                    </b-col>
                    <b-col
                      v-if="canRemove || canDownload"
                      cols="3"
                      class="pl-0"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <b-button-group size="sm">
                        <b-button
                          v-if="canDownload"
                          variant="primary"
                          @click="download(src)"
                        >
                          <feather-icon icon="DownloadIcon" />
                        </b-button>
                        <b-button
                          v-if="canRemove"
                          variant="primary"
                          @click="remove(src)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

          </template>
        </viewer>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>

import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import AggiungiDocumento from './Aggiungi.vue'

export default {
  components: {
    AggiungiDocumento,
    Viewer,
  },
  props: {
    scheda: {
      type: Object,
      required: true,
    },
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      images: [],
      gallery: null,
      difetti: [],
      canAdd: this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIUNGI_FOTO.can
          && !this.scheda.isApprovata && !this.scheda.isValidata
          && (this.$isReferente || this.ispezione.isResponsabile),
      canRemove: this.$grants.ISPEZIONI_EDITABILI_SCHEDE_ELIMINA_FOTO.can
          && !this.scheda.isApprovata && !this.scheda.isValidata
          && (this.$isReferente || this.ispezione.isResponsabile),
      canDownload: this.$grants.ISPEZIONI_SCHEDE_SCARICA_FOTO.can,
    }
  },
  created() {
    this.loading = true

    this.difetti = this.scheda.difetti.sort((a, b) => {
      if (a.posizione > b.posizione) return 1
      if (a.posizione < b.posizione) return -1
      return 0
    }).map(e => ({ id: e.id, label: e.descrizione }))

    this.$remote.schede.foto_all(this.scheda.id)
      .then(res => { this.images = res.data })
      .catch(res => { this.showErrorMessage(res) })
      .finally(() => { this.loading = false })
  },
  methods: {
    inited(viewer) {
      this.gallery = viewer
    },
    add(params, difettoId) {
      let descDifetto = null

      if (this.isNotNullOrUndefined(difettoId)) {
        const difetto = this.scheda.difetti.filter(f => f.id === difettoId)[0]
        this.addRowAtStart(difetto.documenti, params)

        descDifetto = difetto.descrizione
      } else {
        this.addRowAtStart(this.scheda.documenti, params)
      }

      this.addRowAtStart(this.images, ({
        id: params.id, descrizione: params.descrizione, difetto: descDifetto, path: params.url, difettoId, nome: params.nome,
      }))

      this.showAddSuccessMessage()
    },
    remove(image) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.schede.foto_remove(this.scheda.id, image.id)
          .then(() => {
            this.deleteRow(this.images, this.images.indexOf(image))

            if (this.isNotNullOrUndefined(image.difettoId)) {
              const difetto = this.scheda.difetti.filter(f => f.id === image.difettoId)[0]
              const documento = difetto.documenti.filter(f => f.id === image.id)[0]
              this.deleteRow(difetto.documenti, difetto.documenti.indexOf(documento))
            } else {
              const documento = this.scheda.documenti.filter(f => f.id === image.id)[0]
              this.deleteRow(this.scheda.documenti, this.scheda.documenti.indexOf(documento))
            }

            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    download(image) {
      this.loading = true
      this.$remote.schede.foto_download(this.scheda.id, image.id)
        .then(response => {
          this.downloadFile(response.data, image.nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
