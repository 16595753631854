const VALUES = Object.freeze({
  E02: {
    id: 1, label: '0.2', codice: 1, text: '0.2', value: 1,
  },
  E05: {
    id: 2, label: '0.5', codice: 2, text: '0.5', value: 2,
  },
  E1: {
    id: 3, label: '1', codice: 3, text: '1', value: 3,
  },
})

export default VALUES
