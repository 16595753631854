<template>
  <b-overlay :show="loading">
    <b-card header="Ispezione">

      <b-card-body>

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row v-if="!hide">
              <b-col cols="6">

                <cs-input
                  id="componente"
                  v-model="scheda.codice"
                  label="Componenete"
                  read-only
                />

              </b-col>
              <b-col cols="6">

                <cs-input
                  id="stato"
                  v-model="scheda.stato"
                  label="Stato Ispezione"
                  read-only
                />

              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">

                <cs-input
                  id="note"
                  v-model="scheda.note"
                  :read-only="!canUpdate"
                  label="Note"
                />
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
      <b-card-footer v-if="canUpdate">
        <cs-submit-button :click="update">
          Aggiorna
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>

</template>

<script>

export default {
  props: {
    scheda: {
      type: Object,
      required: true,
    },
    ispezione: {
      type: Object,
      required: true,
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      canUpdate: this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA_NOTE_GENERALI.can
        && !this.ispezione.isApprovata && !this.ispezione.isValidata
        && (this.$isReferente || this.ispezione.isResponsabile),
    }
  },
  methods: {
    update() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.schede.update(this.scheda)
            .then(() => { this.showUpdateSuccessMessage() })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
