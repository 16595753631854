<template>
  <CsTableCard
    :loading="loading"
    :card-header="'Scheda n.' + scheda.numero"
    :rows="rows"
    :columns="columns"
    :fixed-header="true"
    max-height="100vh"
    :row-style-class-fn="rowStyleClassFn"
  >
    <template slot="topLeft">
      <b-row>
        <b-col
          cols="2"
          class="mr-2 pr-2"
        >
          <b-button
            variant="primary"
            title="Salva"
            size="md"
            class="mt-2"
            :disabled="!canSave"
            @click="salva()"
          >Salva
          </b-button>
        </b-col>

        <b-col cols="3">
          <cs-select
            id="stato"
            v-model="defaultStato"
            :options="Object.values(stato.options)"
            label="Stato"
            :reduce="r => r.id"
            :disabled="!canSave"
            @input="setAllStato"
          />
        </b-col>

        <b-col
          cols="1"
        >

          <b-form-group
            label="Visto"
            label-for="visto"
          >
            <b-checkbox
              id="visto"
              v-model="defaultVisto"
              variant="primary"
              title="Tutto su visto"
              :disabled="!canSave"
              @change="setAllVisto"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'visto'">
        <b-checkbox
          v-model="rows.filter(f => f.id === props.row.id)[0].visto"
          :disabled="!isUnlocked"
          @change="onVistoChange(props.row)"
        />
      </span>
      <span v-else-if="props.column.field === 'ps'">
        <b-checkbox
          v-if="props.row.mPs"
          v-model="rows.filter(f => f.id === props.row.id)[0].ps"
          :disabled="props.row.inId === null || props.row.esId === null || !isUnlocked"
          @change="onPsChange(props.row)"
        />
      </span>
      <span
        v-else-if="props.column.field === 'statoId'"
        @click="onDoubleClickStato(props.row)"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="rows.filter(f => f.id === props.row.id)[0].statoId"
            :disabled="!isUnlocked"
            :options="stato.options"
            @change="onStatoChange(props.row)"
          />
        </b-form-group>
      </span>
      <span
        v-else-if="props.column.field === 'esId'"
        @click="onDoubleClickEstensione(props.row)"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="rows.filter(f => f.id === props.row.id)[0].esId"
            :disabled="!isUnlocked"
            :options="estensioneOptions(props.row)"
            @change="onEstensioneChange(props.row)"
          />
        </b-form-group>
      </span>
      <span
        v-else-if="props.column.field === 'inId'"
        @click="onDoubleClickIntensita(props.row)"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="rows.filter(f => f.id === props.row.id)[0].inId"
            :disabled="!isUnlocked"
            :options="intensitaOptions(props.row)"
            @change="onIntensitaChange(props.row)"
          />
        </b-form-group>
      </span>
      <span v-else-if="props.column.field === 'note'">
        <b-textarea
          v-model="rows.filter(f => f.id === props.row.id)[0].note"
          :disabled="!isUnlocked"
        />

      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>

    </template>

  </CsTableCard>
</template>

<script>
import STATI_ISPEZIONE from '@/models/statoSchedaEnum'
import STATI_DIFETTO from '@/models/statoDifettoEnum'
import INTENSITA from '@/models/intensitaEnum'
import ESTENSIONE from '@/models/estensioneEnum'

export default {
  props: {
    scheda: {
      type: Object,
      required: true,
    },
    ispezione: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: '#',
          field: 'posizione',
          sortFn: this.numberSort,
          thClass: 'my-less',
          tdClass: 'my-less',
        },
        {
          label: 'Codice',
          field: 'codice',
          thClass: 'my-med',
          tdClass: 'my-med',
        },
        {
          label: 'Descrizione',
          field: 'descrizione',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
          thClass: 'my-too-big',
          tdClass: 'my-too-big',
        },
        {
          label: 'Visto',
          field: 'visto',
          sortFn: this.presenceSort,
          thClass: 'my-less',
          tdClass: 'my-less',
        },
        {
          label: 'G',
          field: 'g',
          sortFn: this.numberSort,
          thClass: 'my-less',
          tdClass: 'my-less',
        },
        {
          label: 'Estensione',
          field: 'esId',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
            filterFn: this.estensioneFilter,
          },
          thClass: 'my-too-big',
          tdClass: 'my-too-big vgt-right-align',
        },
        {
          label: 'Intensità',
          field: 'inId',
          sortFn: this.presenceSort,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
            filterFn: this.intensitaFilter,
          },
          thClass: 'my-too-big',
          tdClass: 'my-too-big vgt-right-align',
        },
        {
          label: 'N. Foto',
          field: row => row.documenti.length,
          sortFn: this.numberSort,
          thClass: 'my-less',
          tdClass: 'my-less',
        },
        {
          label: 'PS',
          field: 'ps',
          sortFn: this.presenceSort,
          thClass: 'my-less',
          tdClass: 'my-less',
        },
        {
          label: 'NA/NR/NP',
          field: 'statoId',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
            filterFn: this.statoFilter,
          },
          sortFn: this.presenceSort,
          thClass: 'my-too-big',
          tdClass: 'my-too-big',
        },
        {
          label: 'Note',
          field: 'note',
          thClass: 'my-big',
          tdClass: 'my-big',
          sortable: false,
        },
      ],
      rows: [],
      stato: {
        options: STATI_DIFETTO,
        lastValue: null,
      },
      lastEsValue: null,
      lastInValue: null,
      defaultVisto: false,
      defaultStato: null,
      // isOredering: false,
    }
  },
  computed: {
    isUnlocked() {
      return this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA.can
          && !this.scheda.isValidata && !this.scheda.isApprovata
          && (this.$isReferente || this.ispezione.isResponsabile)
    },
    canSave() {
      return this.rows.filter(f => (f.inId === null && f.esId !== null) || (f.inId !== null && f.esId === null)).length <= 0
        && this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA.can
        && !this.scheda.isValidata && !this.scheda.isApprovata
        && (this.$isReferente || this.ispezione.isResponsabile)
    },
  },
  created() {
    this.rows = this.scheda.difetti.sort((a, b) => {
      if (a.posizione > b.posizione) return 1
      if (a.posizione < b.posizione) return -1
      return 0
    })

    if (this.scheda.statoId === STATI_ISPEZIONE.IN_COMPILAZIONE.codice) {
      this.setAllVisto(true)
      this.setAllStato(STATI_DIFETTO.NP.value)
      this.defaultVisto = true
      this.defaultStato = STATI_DIFETTO.NP.value
    }
    if (this.rows.length === this.rows.filter(f => f.visto === true).length) {
      this.defaultVisto = true
      // this.defaultStato = STATI_DIFETTO.NP.value
    }
  },
  methods: {
    onVistoChange(row) {
      if (row.statoId === STATI_DIFETTO.NA.id) {
        this.rows.filter(f => f.id === row.id)[0].statoId = null
      }
    },
    onPsChange(row) {
      if (row.ps) {
        const hasEs = this.rows.filter(f => f.id === row.id)[0].esId !== null
        const hasIn = this.rows.filter(f => f.id === row.id)[0].inId !== null

        if (hasEs && hasIn) {
          this.rows.filter(f => f.id === row.id)[0].statoId = null
          this.rows.filter(f => f.id === row.id)[0].visto = true
        } else {
          this.rows.filter(f => f.id === row.id)[0].ps = false
          this.showWarningSwal('Compila prima un estensione e un intensità')
        }
      }
    },
    onStatoChange(row) {
      this.rows.filter(f => f.id === row.id)[0].esId = null
      this.rows.filter(f => f.id === row.id)[0].inId = null
      this.rows.filter(f => f.id === row.id)[0].visto = row.statoId !== STATI_DIFETTO.NA.id
      if (row.mPs) this.rows.filter(f => f.id === row.id)[0].ps = false
    },
    onEstensioneChange(row) {
      this.rows.filter(f => f.id === row.id)[0].visto = true
      this.rows.filter(f => f.id === row.id)[0].statoId = null

      const intensita = this.intensitaOptions(row)
      if (intensita.length === 1 && row.inId === null) {
        this.rows.filter(f => f.id === row.id)[0].inId = intensita[0].id
      }
    },
    onIntensitaChange(row) {
      this.rows.filter(f => f.id === row.id)[0].visto = true
      this.rows.filter(f => f.id === row.id)[0].statoId = null

      const estensioni = this.estensioneOptions(row)
      if (estensioni.length === 1 && row.esId === null) {
        this.rows.filter(f => f.id === row.id)[0].esId = estensioni[0].id
      }
    },

    onDoubleClickStato(row) {
      if (this.stato.lastValue === row.statoId) {
        this.rows.filter(f => f.id === row.id)[0].statoId = null
        this.stato.lastValue = null
      } else {
        this.stato.lastValue = row.statoId
      }
    },
    onDoubleClickEstensione(row) {
      if (this.lastEsValue === row.esId) {
        this.rows.filter(f => f.id === row.id)[0].esId = null
        this.lastEsValue = null
      } else {
        this.lastEsValue = row.esId
      }
    },
    onDoubleClickIntensita(row) {
      if (this.lastInValue === row.inId) {
        this.rows.filter(f => f.id === row.id)[0].inId = null
        this.lastInValue = null
      } else {
        this.lastInValue = row.inId
      }
    },

    estensioneOptions(row) {
      const array = []
      if (row.mEs02) this.addRowAtEnd(array, ESTENSIONE.E02)
      if (row.mEs05) this.addRowAtEnd(array, ESTENSIONE.E05)
      if (row.mEs1) this.addRowAtEnd(array, ESTENSIONE.E1)
      return array
    },
    intensitaOptions(row) {
      const array = []
      if (row.mIn02) this.addRowAtEnd(array, INTENSITA.I02)
      if (row.mIn05) this.addRowAtEnd(array, INTENSITA.I05)
      if (row.mIn1) this.addRowAtEnd(array, INTENSITA.I1)
      return array
    },

    setAllStato(params) {
      for (let i = 0; i < this.rows.length; i += 1) {
        this.rows[i].statoId = params
        this.rows[i].esId = null
        this.rows[i].inId = null
        if (params === STATI_DIFETTO.NA.id) this.rows[i].visto = false
      }
    },
    setAllVisto(params) {
      for (let i = 0; i < this.rows.length; i += 1) {
        this.rows[i].visto = params
        if (params && this.rows[i].statoId === STATI_DIFETTO.NA.id) {
          this.rows[i].statoId = null
        }
      }
    },

    rowStyleClassFn(row) {
      if ((row.esId !== null && row.inId === null) || (row.esId === null && row.inId !== null)) return 'es-in-row'
      return row.ps ? 'ps-row' : ''
    },

    statoFilter(data, filterString) {
      return Object.values(STATI_DIFETTO)
        .filter(f => f.id === data)
        .map(m => m.label.toLowerCase()).includes(filterString.toLowerCase())
    },
    estensioneFilter(data, filterString) {
      const loweString = filterString.toLowerCase()
      const labels = Object.values(ESTENSIONE).filter(f => f.id === data).map(m => m.label.toLowerCase())
      return labels.includes(loweString) || labels.map(m => m.replaceAll(/\./g, '')).includes(loweString)
    },
    intensitaFilter(data, filterString) {
      const loweString = filterString.toLowerCase()
      const labels = Object.values(INTENSITA).filter(f => f.id === data).map(m => m.label.toLowerCase())
      return labels.includes(loweString) || labels.map(m => m.replaceAll(/\./g, '')).includes(loweString)
    },

    salva() {
      let block = false
      let cod = null
      for (let i = 0; i < this.rows.length; i += 1) {
        const row = this.rows[i]
        if ((row.esId !== null && row.inId === null) || (row.esId === null && row.inId !== null)) {
          block = true
          cod = row.codice
          break
        }
      }

      if (block) {
        this.showWarningSwal(`Il difetto ${cod} non è stato compilato correttamente`)
        return
      }

      this.loading = true
      this.$remote.schede.diffetti_update(this.scheda.id, this.rows)
        .then(() => {
          this.showUpdateSuccessMessage()
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
  },
}
</script>

<style lang="scss">
.my-big {
  min-width: 125px!important;
  width: 125px!important;
}
.my-less {
  min-width: 50px !important;
  width: 50px !important;
}
.my-med {
  min-width: 100px !important;
  width: 100px !important;
}
.my-too-big {
  min-width: 165px !important;
  width: 165px !important;
}
.ps-row {
  background-color: #cd5c5c54 !important;
}

.dark-layout .es-in-row {
  td {
    background-color: #a3893273 !important;
  }
}
.es-in-row {
  background-color: #CDB35CCF !important;
}
</style>
