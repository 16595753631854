const VALUES = Object.freeze({
  NA: {
    id: 3, label: 'NA', codice: 3, text: 'NA', value: 3,
  },
  NR: {
    id: 2, label: 'NR', codice: 2, text: 'NR', value: 2,
  },
  NP: {
    id: 1, label: 'NP', codice: 1, text: 'NP', value: 1,
  },
})

export default VALUES
