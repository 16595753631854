<template>
  <b-tabs
    class="cs-tabs"
    card
    justified
  >
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Informazioni"
    >
      <informazioni
        :scheda="scheda"
        :ispezione="ispezione"
      />
    </cs-tab>
    <!--    <cs-tab-->
    <!--      :show="!loading && canDrive"-->
    <!--      :is-loading="loading"-->
    <!--      text="Drive"-->
    <!--    >-->
    <!--      <drive-->
    <!--        :scheda="scheda"-->
    <!--        :ispezione="ispezione"-->
    <!--      />-->
    <!--    </cs-tab>-->
    <cs-tab
      :show="!loading && canDrive"
      :is-loading="loading"
      text="Galleria"
    >
      <galleria
        :scheda="scheda"
        :ispezione="ispezione"
      />
    </cs-tab>
    <cs-tab
      :show="!loading"
      :is-loading="loading"
      text="Scheda"
      active
    >
      <informazioni
        :scheda="scheda"
        :ispezione="ispezione"
        :hide="true"
      />
      <scheda
        :scheda="scheda"
        :ispezione="ispezione"
      />
    </cs-tab>

  </b-tabs>
</template>

<script>
import Informazioni from './Informazioni.vue'
import Scheda from './Scheda.vue'
import Galleria from './drive/Galleria.vue'

export default {
  components: {
    Informazioni,
    // Drive,
    Scheda,
    Galleria,
  },
  data() {
    return {
      ispezione: null,
      scheda: {
        dataInizioCompilazione: null,
        dataCompilazione: null,
        compilatore: null,
        dataValidazione: null,
        validatore: null,
        dataApprovazione: null,
        approvatore: null,
        note: null,
        difetti: [],
        documenti: [],
      },
      loading: true,
      canDrive: this.$grants.ISPEZIONI_EDITABILI_SCHEDE_AGGIUNGI_FOTO.can
        || this.$grants.ISPEZIONI_EDITABILI_SCHEDE_ELIMINA_FOTO.can
        || this.$grants.ISPEZIONI_SCHEDE_SCARICA_FOTO.can,
    }
  },
  created() {
    if (!this.$grants.ISPEZIONI_VISUALIZZA_DETTAGLIO.can) this.$routing.ERRORE401.push()

    const { anno, codice } = this.$route.params
    if (this.isNotNullOrUndefined(anno) && this.isNotNullOrUndefined(codice)) {
      this.$remote.ispezioni.idFromBim(anno, codice)
        .then(res => this.getData(res.data.id, res.data.schedaId))
    } else {
      this.getData(this.$route.params.id, this.$route.params.schedaId)
    }
  },
  methods: {
    getData(id, schedaId) {
      this.$remote.ispezioni.single(id)
        .then(resIsp => {
          this.ispezione = resIsp.data

          if (!this.ispezione.isApprovata && this.$isConcessionario) this.$routing.ERRORE401.push()

          this.$remote.schede.single(schedaId)
            .then(resScheda => {
              this.scheda = resScheda.data

              const breadcrumbData = {
                id,
                schedaId,
                operaId: this.ispezione.opera.id,
                operaNome: this.ispezione.opera.nome,
                data: new Date(this.ispezione.dataIspezione).toLocaleDateString(),
                componente: `${this.scheda.codice} Scheda ${this.scheda.numero}`,
              }
              this.setBreadcrumbData(this.$store, this.$route.name, breadcrumbData)

              this.model = resScheda.data
            })
            .catch(res => {
              this.showReposonseMessage(res)
              this.$routing.ERRORE404.push()
            })
            .finally(() => { this.loading = false })
        })
        .catch(res => {
          this.showReposonseMessage(res)
          this.$routing.ERRORE404.push()
        })
    },
  },
}
</script>
